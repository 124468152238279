import { State } from "./State";

//Adapted from: https://dev.to/ramonak/react-how-to-create-a-custom-progress-bar-component-in-5-minutes-2lcl

const pendingColor = "#e0e0de";
const completedColor = "#3e98c7";

const outerStyle = {
  margin: "auto",
  boxSizing: "border-box",
  padding: "0 10px",
  width: "100%",
  maxWidth: "500px",
};

const containerStyles = {
  width: "100%",
  height: 20,
  backgroundColor: pendingColor,
  borderRadius: 50,
};

const labelStyles = {
  padding: 5,
  color: "white",
  fontWeight: "bold",
};

let activeUploads = new Set();
let clearActiveUploads = null;
const uploadQuietPeriod = 5000; // ms

export default function UploadProgress({ uploads }) {
  // Reset active uploads if there has been no recent activity
  clearTimeout(clearActiveUploads);
  clearActiveUploads = setTimeout(
    () => activeUploads.clear(),
    uploadQuietPeriod
  );

  const totalCount = uploads.length;
  if (!totalCount) {
    return;
  } // hide component if no uploads

  let progressTotal = 0;
  let progressCount = 0;
  let failedCount = 0;
  let completedCount = 0;
  if (uploads) {
    for (let i of uploads) {
      switch (i.state) {
        case State.Completed:
          completedCount++;
          if (!activeUploads.has(i.uuid)) break;
        // else, completed upload was recently active, so fallthrough
        case State.Pending:
        case State.Uploading:
          progressCount++;
          progressTotal += i.progress ? i.progress : 0;
          activeUploads.add(i.uuid);
          break;
        case State.Failed:
          failedCount++;
          break;
        default:
          throw new Error("Invalid State", i.state);
      }
    }
  }

  const completed = progressCount
    ? Math.ceil(progressTotal / progressCount)
    : completedCount
    ? 100
    : 0;

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: completedColor,
    borderRadius: "inherit",
    textAlign: "right",
    transition: "width 0.2s linear",
  };

  return (
    <>
      <div style={outerStyle}>
        <div style={containerStyles}>
          <div style={fillerStyles}>
            <span style={labelStyles}>{`${completed}%`}</span>
          </div>
        </div>
      </div>
      <div>
        Files Uploaded: {completedCount} <br />
        {/* TODO clean up counts */}
        {failedCount !== 0 && `Files Failed to Upload: ${failedCount}`}
      </div>
    </>
  );
}
