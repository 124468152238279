const State = {
  Pending: 0,
  Uploading: 1,
  Completed: 2,
  Failed: 3,
};

function getNewState(oldState, newState) {
  if (newState === null) {
    return oldState;
  }
  switch (oldState) {
    case State.Pending:
      return newState;
    case State.Failed:
      return State.Failed;
    case State.Completed:
      return newState === State.Failed ? State.Failed : State.Completed;
    case State.Uploading:
      if (newState !== State.Pending) {
        return newState;
      } else {
        return oldState;
      }
    default:
      throw new Error("Invalid State", oldState);
  }
}

export { State, getNewState };
