import "./UploadIcon.scss";

// Upload Icon Image
// SVG adapted from https://www.svgrepo.com/svg/73906/cloud-upload
// Original saved in /resources/
export default function UploadIcon() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 228.2 228.2"
      className="UploadIcon"
    >
      <g>
        <circle cx="114.1" cy="114.1" r="113.6" />
        <g>
          <path
            d="M171.7,77c-8-14.3-23.8-22.5-40.7-19.8c-6.6-13.9-20.5-22.8-36.2-22.8c-22.1,0-40.1,18-40.1,40.1
                        c0,0.9,0,1.9,0.1,2.8c-19.1,4.3-33.1,21.6-33.1,41.4c0,23.5,19.1,42.5,42.5,42.5h33.6v-7H64.3c-19.6,0-35.5-15.9-35.5-35.5
                        c0-17.5,13.1-32.6,30.4-35.1c0.9-0.1,1.8-0.6,2.3-1.4c0.6-0.8,0.8-1.7,0.6-2.6c-0.3-1.9-0.4-3.6-0.4-5.1
                        c0-18.2,14.9-33.1,33.1-33.1c13.7,0,25.8,8.3,30.8,21c0.6,1.6,2.4,2.5,4.1,2.1c15.4-3.6,29.9,3.9,36.6,17.2c0.5,1,1.5,1.7,2.6,1.9
                        c17.4,2.5,30.5,17.6,30.5,35.1c0,19.6-15.9,35.5-35.5,35.5h-44.8v7h44.8c23.5,0,42.5-19.1,42.5-42.5
                        C206.5,98.3,191.7,80.7,171.7,77z"
          />
        </g>
        <g>
          <path
            d="M130.4,117c1.4-1.4,1.4-3.7,0-5.1l-20-20c-0.7-0.7-1.6-1-2.5-1l-0.1,0c0,0,0,0-0.1,0c-0.9,0-1.8,0.4-2.5,1
                        l-20,20c-1.4,1.4-1.4,3.7,0,5.1c1.4,1.4,3.7,1.4,5.1,0l13.9-13.9v97.2c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6v-97.4l14,14
                        C126.7,118.4,129,118.4,130.4,117z"
          />
        </g>
      </g>
    </svg>
  );
}
