import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { State } from "./State";

import "react-circular-progressbar/dist/styles.css";
import "./Image.css";
import classNames from "classnames";

const progressColor = "#3e98c7";
const failColor = "#fa5050";

// SVG from https://commons.wikimedia.org/wiki/File:Eo_circle_green_checkmark.svg
const checkmark = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    enableBackground="new 0 0 64 64"
    className="checkmark overlay"
  >
    <path
      d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50  l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z"
      fill={progressColor}
    />
  </svg>
);

// SVG from https://www.onlinewebfonts.com/icon/395688
const retry = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
    className="retry overlay"
  >
    <path
      d="M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c270.6,0,490-219.4,490-490C990,229.4,770.6,10,500,10z M714.1,706.5c-57.2,57.2-133.2,88.7-214.1,88.7c-80.9,0-156.9-31.5-214.1-88.7c-57.2-57.2-88.7-133.2-88.7-214.1c0-80.9,31.5-156.9,88.7-214.1c57.2-57.2,133.2-88.7,214.1-88.7v-72.9l151.6,122.7L500,362v-88.4c-120.6,0-218.7,98.1-218.7,218.8c0,120.6,98.1,218.8,218.8,218.8c120.6,0,218.8-98.1,218.8-218.8h84.1C802.8,573.3,771.3,649.3,714.1,706.5L714.1,706.5z"
      fill={failColor}
    />
  </svg>
);

export default function Image({
  src,
  videoType,
  videoUrl,
  state,
  progress,
  onClick,
  hideImage,
}) {
  let image = <img src={src} alt="" />;
  if (videoType) {
    image = (
      <picture>
        <source srcSet={videoUrl} type={videoType} />
        {image}
      </picture>
    );
  }

  let overlay;
  switch (state) {
    case State.Completed:
      overlay = checkmark;
      break;
    case State.Failed:
      overlay = retry;
      break;
    case State.Uploading:
      overlay = (
        <CircularProgressbar
          className="overlay"
          value={progress}
          strokeWidth={12}
        />
      );
      break;
    default:
      throw new Error("Invalid State", state);
  }

  return (
    <div
      className={classNames({
        Image: true,
        complete: state === State.Completed,
      })}
      onClick={onClick}
      onAnimationEndCapture={(e) => {
        if (
          e.animationName === "fadeOut" &&
          e.target.classList.contains("complete")
        ) {
          hideImage();
        }
      }}
    >
      {image}
      {overlay}
    </div>
  );
}
