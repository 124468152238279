import "./App.css";

import Gallery from "./Gallery.js";
import UploadForm from "./UploadForm.js";
import { getVideoFrame } from "./VideoHelper";
import { useState, useEffect } from "react";
import { State, getNewState } from "./State";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { connectStorageEmulator } from "firebase/storage";
import NameEntryForm from "./NameEntryForm";
import { v4 as uuidv4 } from "uuid";
import mime from "mime";

import UAParser from "ua-parser-js";
import UploadProgress from "./UploadProgress";

// Project owner: wedding-pics@bocon.us
const firebaseConfig = {
  apiKey: "AIzaSyD35KDSD-9ezkL8WtBa8ZTXSGXaJw9_GMA",
  authDomain: "wedding-pics-10628.firebaseapp.com",
  projectId: "wedding-pics-10628",
  storageBucket: "wedding-pics-10628.appspot.com",
  messagingSenderId: "555131035614",
  appId: "1:555131035614:web:be679ca98310da6e70474f",
  measurementId: "G-8XHFQK31VM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Create a root reference
const storage = getStorage(app);
if (process.env.NODE_ENV === "development") {
  connectStorageEmulator(storage, window.location.hostname, 9199);
}

const MAX_PARALLEL_UPLOADS = 4;

const filesToExclude = new Set([
  // OS-generated files (OS X)
  ".DS_Store",
  ".Spotlight-V100",
  ".Trashes",

  // OS-generated files (Windows)
  "desktop.ini",
  "ehthumbs.db",
  "Thumbs.db",
]);

function filepath(file, path) {
  return [...path, file.name].join("/");
}

export default function App() {
  const [name, setName] = useState(null);
  const [uploads, setUploads] = useState([]);
  // TODO should we persist uploads to local storage and resume on refresh?

  useEffect(() => {
    tryUpload(uploads, name, setUploads);
  }, [name, uploads]);

  function onFileSelected(file, path = []) {
    if (
      filesToExclude.has(file.name) ||
      file.name.startsWith("._") ||
      file.name.endsWith(".swp") ||
      file.name.endsWith("~")
    ) {
      console.log("Skipping", filepath(file, path));
      return;
    }
    // TODO ask user if they want to upload JPEG or HEIC if on iOS17
    console.log("Queueing", filepath(file, path));
    const uuid = uuidv4();
    // Fixing a bug where content type isn't always set
    const contentType =
      file.type || mime.getType(file.name) || "application/octet-stream";
    setUploads((uploads) => [
      ...uploads,
      {
        file,
        contentType,
        path,
        uuid,
        state: State.Pending,
      },
    ]);
    logEvent(analytics, "upload_queued", {
      content_type: contentType,
      fileName: file.name,
      uuid,
      userName: name,
    });
  }

  function updateName(name) {
    setName(name);
    logEvent(analytics, "set_name", {
      userName: name,
    });
    setUserProperties(analytics, { user: name });
  }

  return (
    <div className="App">
      <NameEntryForm onComplete={updateName} />
      {name && (
        <>
          <UploadProgress uploads={uploads} />
          <UploadForm fileSelected={onFileSelected} />
          <Gallery uploads={uploads} />
        </>
      )}
    </div>
  );
}

// Tries to start pending uploads
function tryUpload(uploads, name, setUploads) {
  /*
   * Uploads are always added to the end of the list,
   * so queued uploads will always follow active uploads.
   *
   * Revisit this code if that assumption changes.
   */
  let activeUploads = 0;
  for (const u of uploads) {
    if (u.state === State.Uploading) {
      if (++activeUploads === MAX_PARALLEL_UPLOADS) {
        break;
      }
    } else if (u.state === State.Pending) {
      startUpload(u, name, setUploads);
      activeUploads++;
      break;
    }
  }
  if (activeUploads > MAX_PARALLEL_UPLOADS) {
    console.log("Too many active uploads");
  }
}

// Starts the pending uploads
async function startUpload(task, name, setUploads) {
  const pendingUpload = { ...task };
  const { file, contentType, path, uuid } = pendingUpload;
  console.log("Uploading", filepath(file, path));

  const fileUrl = URL.createObjectURL(file);
  const mimeCat = contentType.split("/").shift();
  const fileExt = file.name.split(".").pop();
  const uniqueUploadPath = `${mimeCat}/${uuid}.${fileExt}`;
  // const commonUploadPath = `${mimeCat}/${file.name}`;

  // Get thumbnail for gallery
  pendingUpload.state = State.Uploading;
  pendingUpload.imageUrl = "/unknown-file.svg";
  pendingUpload.videoType = null;
  pendingUpload.videoUrl = null;

  if (mimeCat === "image") {
    pendingUpload.imageUrl = fileUrl;
  } else {
    if (mimeCat === "video") {
      pendingUpload.videoType = contentType;
      pendingUpload.videoUrl = fileUrl;
    }

    const browserName = new UAParser().getBrowser().name;
    if (!browserName.toLowerCase().includes("safari")) {
      // Safari fails to load video frame and can render video as image,
      // so bypass
      try {
        pendingUpload.imageUrl = await getVideoFrame(fileUrl);
      } catch (error) {
        console.log("Error getting frame from video:", error);
      }
    }
  }

  // Build file metadata
  const metadata = {
    contentType,
    customMetadata: {
      userName: name,
      fileName: file.name,
    },
  };
  if (path) {
    metadata.customMetadata.path = path.join("/");
  }
  if (file.lastModified) {
    metadata.customMetadata.lastModified = file.lastModified;
  }

  if(window.navigator?.userAgent) {
    metadata.customMetadata.userAgent = window.navigator.userAgent;
  }

  // Upload file and metadata
  const storageRef = ref(storage, uniqueUploadPath);
  const uploadTask = uploadBytesResumable(storageRef, file, metadata);

  /*
  In addition to starting uploads, you can pause, resume, and cancel uploads using the
  pause(), resume(), and cancel() methods. Calling pause() or resume() will raise pause
  or running state changes. Calling the cancel() method results in the upload failing and
  returning an error indicating that the upload was canceled.
  */
  pendingUpload.onClick = () => {
    switch (uploadTask.snapshot.state) {
      // options: 'running' | 'paused' | 'success' | 'canceled' | 'error'
      case "paused":
        // uploadTask.resume();
        break;
      case "running":
        // uploadTask.cancel();
        // uploadTask.pause();
        break;
      case "canceled":
      case "error":
        // Retry
        setUploads((uploads) => [
          ...uploads.filter((u) => u.uuid !== uuid),
          {
            file,
            path,
            contentType,
            uuid: uuidv4(),
            state: State.Pending,
          },
        ]);
        break;
      case "success":
      default:
      // Nothing to do...
    }
  };

  const updateUpload = (progress, newState, display = true) => {
    setUploads((uploads) =>
      uploads.map((u) =>
        u.uuid === uuid
          ? {
              ...u,
              progress,
              display,
              state: getNewState(u.state, newState),
            }
          : u
      )
    );
  };

  setUploads((uploads) =>
    uploads.map((u) => (u.uuid === uuid ? pendingUpload : u))
  );

  pendingUpload.hideImage = () => {
    updateUpload(100, null, false);
  };

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      updateUpload(progress, State.Uploading);
    },
    (error) => {
      updateUpload(-1, State.Failed);
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      // switch (error.code) {
      //   case "storage/unauthorized":
      //     // User doesn't have permission to access the object
      //     break;
      //   case "storage/canceled":
      //     // User canceled the upload
      //     break;

      //   // ...

      //   case "storage/unknown":
      //     // Unknown error occurred, inspect error.serverResponse
      //     console.log(error.message);
      //     console.log(error.customData);
      //     break;
      //   default:
      //    break;
      // }
      logEvent(analytics, "upload_failed", {
        content_type: contentType,
        fileName: file.name,
        uuid,
        userName: name,
        error_code: error.code,
        error_message: error.message,
        error_data: error.customData,
      });
    },
    () => {
      updateUpload(100, State.Completed);
      logEvent(analytics, "upload_complete", {
        content_type: contentType,
        fileName: file.name,
        uuid,
        userName: name,
      });
    }
  );
}

/*
Firebase
Code  Reason

Fatal errors:
storage/unknown	An unknown error occurred.
storage/object-not-found	No object exists at the desired reference.
storage/bucket-not-found	No bucket is configured for Cloud Storage
storage/project-not-found	No project is configured for Cloud Storage
storage/unauthenticated	User is unauthenticated, please authenticate and try again.
storage/unauthorized	User is not authorized to perform the desired action, check your security rules to ensure they are correct.
storage/no-default-bucket	No bucket has been set in your config's storageBucket property.

Send note to Brian:
storage/quota-exceeded	Quota on your Cloud Storage bucket has been exceeded. If you're on the no-cost tier, upgrade to a paid plan. If you're on a paid plan, reach out to Firebase support.

Show failure with retry button (increase failed count):
storage/retry-limit-exceeded	The maximum time limit on an operation (upload, download, delete, etc.) has been excceded. Try uploading again.
storage/invalid-checksum	File on the client does not match the checksum of the file received by the server. Try uploading again.
storage/cannot-slice-blob	Commonly occurs when the local file has changed (deleted, saved again, etc.). Try uploading again after verifying that the file hasn't changed.
storage/server-file-wrong-size	File on the client does not match the size of the file recieved by the server. Try uploading again.

Hide image with fade:
storage/canceled	User canceled the operation.
*/

/*
In addition to starting uploads, you can pause, resume, and cancel uploads using the
pause(), resume(), and cancel() methods. Calling pause() or resume() will raise pause
or running state changes. Calling the cancel() method results in the upload failing and
returning an error indicating that the upload was canceled.
*/
